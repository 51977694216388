import React, {useEffect, useMemo, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Team from "./Team";
import DataService from "../services/DataService";

export default function FireMemberModal(props: FireMemberModalProps) {
    const service = useMemo(() => DataService.getInstance(), []);
    const [heimPlayer, setHeimPlayer] = useState<Player[]>([]);
    const [gastPlayer, setGastPlayer] = useState<Player[]>([]);

    useEffect(() => {
        service.team().get(props.saison, String(props.heimId), props.spieltag, team => setHeimPlayer(team.spieler), b => b);
        service.team().get(props.saison, String(props.gastId), props.spieltag, team => setGastPlayer(team.spieler), b => b);
    }, [service, props]);

    return (
        <Modal show={props.show}
               onHide={props.handleClose}
               backdrop="static"
               keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Spieler Feuern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped hover={true}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Team</th>
                        <th>Typ</th>
                        <th>Kosten</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        [...heimPlayer, ...gastPlayer].map(player =>
                            <tr key={player.id} onClick={e => props.handleAdd(player)}>
                                <td>{player.name}</td>
                                <td>
                                    {player.teamId && <Team team={{id: player.teamId, name: ''}} small={true} onlyLogo={true}/>}
                                </td>
                                <td>{player.position}</td>
                                <td>{player.wert}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}