
export class ActionSSP {
    private readonly _id: number;
    private readonly _ssp: number;
    private readonly _nuffleBonus: number;

    public constructor(id:number, ssp: number, bonus: number = 0) {
        this._id = id;
        this._ssp = ssp;
        this._nuffleBonus = bonus;
    }

    public id(): number {
        return this._id
    }

    public ssp(nuffle: boolean): number {
        if(nuffle) {
            return this._ssp + this._nuffleBonus;
        } else {
            return this._ssp;
        }
    }
}