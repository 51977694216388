import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import React, {ChangeEvent, CSSProperties, useEffect, useMemo, useState} from "react";
import Select from "react-select";
import DataService from "../services/DataService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus, faMinus, faCheck, faCancel} from '@fortawesome/free-solid-svg-icons'
import Team from "./Team";
import Form from "react-bootstrap/Form";
import {eventComparator} from "../util/Utils";

const mapSingleValue = (data: BasePlayer | Action | null) : SelectOption | null => {
    if(data == null || data.id === 0) {
        return null;
    }
    return {label: data.name, value: data.id};
};

const mapValuePlayer = (data: BasePlayer): SelectOption => {
    return {label: data.name, value: data.id, teamId: data.teamId};
};

const mapValue = (data: Action): SelectOption => {
    return {label: data.name, value: data.id};
};

const mapToDropDownAction = (data: Action[]) => {
    const options: GroupedActionOption[] = [];

    data.forEach(action => {
        let option = options.find(a => a.label === action.typ);
        if(option === undefined) {
            option = {label: action.typ, options: []};
            options.push(option);
        }
        option.options.push(mapValue(action));
    });

    return options;
}

const mapToDropDownBasePlayer = (teamData: {id: number, name:string, data: BasePlayer[]}[]) => {
    const options: GroupedActionOption[] = [];

    teamData.forEach(team => {
        team.data.forEach(player => {
            let option = options.find(a => a.label === team.name);
            if(option === undefined) {
                option = {label: team.name, options: []};
                options.push(option);
            }
            option.options.push(mapValuePlayer({id: player.id, name: player.name, teamId: team.id}));
        });
    });

    return options;
}

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles: CSSProperties = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = (data: GroupedActionOption) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setData: (b: number) => void) => void = (event, setData) => {
    let { valueAsNumber, min, max } = event.target as HTMLInputElement;
    let value = Math.max(Number(min), Math.min(Number(max), valueAsNumber));
    setData(value);
};

export default function BerichtGame(props: BerichtGameProps) {
    const service = useMemo(() => DataService.getInstance(), []);

    const [ownPlayers, setOwnPlayers] = useState<BasePlayer[]>([]);
    const [enemyPlayers, setEnemyPlayers] = useState<BasePlayer[]>([]);
    const [actions, setActions] = useState<Action[]>([]);
    const [reactions, setReactions] = useState<Action[]>([]);
    //
    const [halbzeit, setHalbzeit] = useState<number>(1);
    const [zug, setZug] = useState<number>(1);
    const [nuffle, setNuffle] = useState<boolean>(false);
    const [akteur, setAkteur] = useState<BasePlayer | null>(null);
    const [action, setAction] = useState<Action | null>(null);
    const [ziel, setZiel] = useState<BasePlayer | null>(null);
    const [reaction, setReaction] = useState<Action | null>(null);

    useEffect(() => {
        service.team().getPlayer(props.saison, props.selbst.id, props.spieltag, setOwnPlayers, (b) => {});
        service.team().getPlayer(props.saison, props.gegner.id, props.spieltag, setEnemyPlayers, (b) => {});
        service.staticData().getActions(setActions, (b) => {});
        service.staticData().getReactions(setReactions, (b) => {});
    }, [service, props.saison, props.spieltag, props.selbst, props.gegner]);

    return (
        <Table striped>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Halbzeit</th>
                    <th>Zug</th>
                    <th>Nuffle</th>
                    <th>Akteur</th>
                    <th>Aktion</th>
                    <th>Ziel</th>
                    <th>Reaktion</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {
                    props.events.sort(eventComparator).map((event, index) =>
                        <tr key={index}>
                            <td>{index+1}</td>
                            <td>{event.halbzeit}</td>
                            <td>{event.zug}</td>
                            <td>
                            {
                                event.nuffle ?
                                    <FontAwesomeIcon icon={faCheck} />
                                :
                                    <FontAwesomeIcon icon={faCancel} />
                            }
                            </td>
                            <td>
                                {
                                    event.akteur.teamId && <Team team={{id: event.akteur.teamId, name: ''}} small={true} onlyLogo={true} />
                                }
                                {event.akteur.name}
                            </td>
                            <td>{event.aktion?.name}</td>
                            <td>
                                {
                                    event.ziel?.teamId && <Team team={{id: event.ziel.teamId, name: ''}} small={true} onlyLogo={true} />
                                }
                                {event.ziel?.name}
                            </td>
                            <td>{event.reAktion?.name}</td>
                            <td>
                                <Button variant="primary" size="sm" onClick={(e) => props.removeEvent(index)}>
                                    <FontAwesomeIcon icon={faMinus} />
                                </Button>
                            </td>
                        </tr>
                    )
                }
                <tr>
                    <td>&nbsp;</td>
                    <td><Form.Control type="number" min={1} max={2} defaultValue={halbzeit} onChange={e => handleChange(e, setHalbzeit)}/></td>
                    <td><Form.Control type="number" min={1} max={8} defaultValue={zug} onChange={e => handleChange(e, setZug)}/></td>
                    <td>
                        <Form.Check.Input defaultChecked={nuffle}
                                          onChange={event => setNuffle(event.target.checked)}
                        />
                    </td>
                    <td>
                        <Select<SelectOption, false, GroupedActionOption>
                            options={mapToDropDownBasePlayer([{
                                id: props.selbst.id,
                                name: props.selbst.name,
                                data: ownPlayers
                            }, {id: props.gegner.id, name: props.gegner.name, data: enemyPlayers}])}
                            value={mapSingleValue(akteur)}
                            placeholder={'Wähle ein Akteur'}
                            onChange={selectedOption => {
                                if (selectedOption != null) {
                                    setAkteur({
                                        id: selectedOption.value,
                                        teamId: selectedOption.teamId,
                                        name: selectedOption.label
                                    });
                                }
                            }}
                        ></Select>
                    </td>
                    <td>
                        <Select<SelectOption, false, GroupedActionOption>
                            options={mapToDropDownAction(actions)}
                            value={mapSingleValue(action)}
                            placeholder={'Wähle eine Aktion'}
                            formatGroupLabel={formatGroupLabel}
                            onChange={selectedOption => {
                                if (selectedOption != null) {
                                    setAction({id: selectedOption.value, typ: '', name: selectedOption.label});
                                }
                            }}
                        ></Select>
                    </td>
                    <td>
                        <Select<SelectOption, false, GroupedActionOption>
                            options={mapToDropDownBasePlayer([{
                                id: -1,
                                name: 'Nix',
                                data: [{id: -1, name: '~~ nix ~~'}]
                            }, {id: props.selbst.id, name: props.selbst.name, data: ownPlayers}, {
                                id: props.gegner.id,
                                name: props.gegner.name,
                                data: enemyPlayers
                            }])}
                            value={mapSingleValue(ziel)}
                            placeholder={'Wähle ein Ziel'}
                            onChange={selectedOption => {
                                if (selectedOption != null) {
                                    if (selectedOption.value > 0) {
                                        setZiel({
                                            id: selectedOption.value,
                                            teamId: selectedOption.teamId,
                                            name: selectedOption.label
                                        });
                                    } else {
                                        setZiel(null);
                                    }
                                }
                            }}
                        ></Select>
                    </td>
                    <td>
                        <Select<SelectOption, false, GroupedActionOption>
                            options={mapToDropDownAction([{id: -1, typ: 'Basic', name: '~~ nix ~~'}, ...reactions])}
                            value={mapSingleValue(reaction)}
                            placeholder={'Wähle eine Reaktion'}
                            formatGroupLabel={formatGroupLabel}
                            onChange={selectedOption => {
                                if (selectedOption != null) {
                                    if (selectedOption.value > 0) {
                                        setReaction({id: selectedOption.value, typ: '', name: selectedOption.label});
                                    } else {
                                        setReaction(null);
                                    }
                                }
                            }}
                        ></Select>
                    </td>
                    <td>
                        <Button variant="primary" size="sm" onClick={(e) => {
                            if (akteur != null && action != null) {
                                props.addEvent({halbzeit: halbzeit, zug: zug, nuffle:nuffle, akteur: akteur, aktion: action, reAktion: reaction, ziel: ziel});
                                setAkteur(null);
                                setNuffle(false);
                                setAction(null);
                                setReaction(null);
                                setZiel(null);
                            }
                        }}>
                            <FontAwesomeIcon icon={faPlus}/>
                        </Button>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
}