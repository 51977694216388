import React, {useEffect, useMemo, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import DataService from "../../services/DataService";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../atoms/user";
import {useParams} from "react-router-dom";
import Team from "../Team";


export default function Wetten() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useRecoilState(userAtom);
    const [wettInfo, setWettInfo] = useState<WettOverview>({
        endDate: 0,
        wetten: [],
        kombi: false,
        spieltag: 0
    });

    // @ts-ignore
    const params: { saison: number } = useParams();

    useEffect(() => {
        if(user.token === "") {
            setLoading(true);
        } else {
            setLoading(true);
            service.dashboard().getWetten(params.saison, user.token, setUser, setWettInfo, setLoading);
        }
    }, [service, user.token, params.saison, setUser]);

    return (
        <Container className={"greyBg"}>
            {
                loading ?
                    <div className="spinner-grow" role="status"/>
                :
                    <p>
                        <Row>
                            <Col><h5>Wetten</h5></Col>
                        </Row>
                        {
                            wettInfo.endDate !== 0 &&
                                <Row>
                                    <Col>Enddate: </Col>
                                    <Col>{new Date(wettInfo.endDate * 1000).toLocaleString()}</Col>
                                </Row>
                        }
                        <Row>
                            <Col>Wetten: </Col>
                        </Row>
                        <Row>
                            <Col>
                            {
                                wettInfo.wetten.length === 0 ?
                                    <span>Du hast für den nächste Spieltag noch keine Wetten abgegeben!</span>
                                :
                                    <>
                                        <Row>
                                            <Col>Kombi:</Col>
                                            <Col>{wettInfo.kombi}</Col>
                                        </Row>
                                        {
                                            wettInfo.wetten.map(paarung =>
                                                <>
                                                    <Row>
                                                        <Col>Einsatz: </Col>
                                                        <Col>{paarung.einsatz}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>Heim</Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>Gast</Col>
                                                        <Col>&nbsp;</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col><Team team={paarung.heim} onlyLogo={true} small={true}/></Col>
                                                        <Col>{paarung.heimPunkte}</Col>
                                                        <Col><Team team={paarung.gast} onlyLogo={true} small={true}/></Col>
                                                        <Col>{paarung.gastPunkte}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>&nbsp;</Col>
                                                    </Row>
                                                </>
                                            )
                                        }
                                    </>
                            }
                            </Col>
                        </Row>
                    </p>
            }
        </Container>);
}