import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import logo from "../asserts/images/blood_bros_league_logo.png"
import {Link} from "react-router-dom";
import Team from "../component/Team";

export default function Preview() {
    return (
        <Container>
            <Row>
                <Col>
                    <h1 className={"text-center"}>Saison Vorschau 2025 / 2026</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <img src={logo} className={"mx-auto d-block"} height={300} width={300} alt={"Logo"}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Container>
                        <Row>
                            <Col>
                                <h3>Feststehende Teams</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Team team={{ id:1, name: "Bitches of Naggaroth"}} />
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col>
                    <Container>
                        <Row>
                            <Col>
                                <h3>Offene Teams</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Team team={{ id:0, name: "Gnome"}} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Team team={{ id:0, name: "Menschen"}} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Team team={{ id:0, name: "Khorne"}} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Team team={{ id:0, name: "Nordmänner"}} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Team team={{ id:0, name: "Elfen Union"}} />
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col>
                    <Container>
                        <Row>
                            <Col>
                                <h3>Ausgeschiedene Teams</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Team team={{ id:8, name: "Da Glitza Spritza"}} />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>
            <Row><Col>&nbsp;</Col></Row>
            <Row><Col>&nbsp;</Col></Row>
            <Row>
                <Col>
                    <Link to={"/"}>Back</Link>
                </Col>
            </Row>
        </Container>
    );
}