import React, {useEffect, useMemo, useState} from 'react';
import {Col, Container, Nav, Navbar, NavDropdown, Row} from "react-bootstrap";
import {Outlet, useParams} from "react-router-dom";
import DataService from "../../services/DataService";
import logo from "../../asserts/images/blood_bros_league_logo.png";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../atoms/user";
import {WETTER_LIST} from "../../util/Utils";

export default function Layout() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [teamsData, setTeamsData] = useState<Team[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useRecoilState(userAtom);
    // @ts-ignore
    const params: { saison: number } = useParams();

    useEffect(() => {
        setLoading(true);
        service.team().getAll(params.saison, setTeamsData, setLoading);
    }, [service, params.saison]);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Navbar className="bg-body-tertiary">
                        <Container>
                            <Navbar.Brand href="/">
                                <img
                                    src={logo}
                                    width="50"
                                    height="50"
                                    className="d-inline-block align-top"
                                    alt="Logo"
                                />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Text>Saison {params.saison}</Navbar.Text>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">
                                    <Nav.Link href={"/saison/"+params.saison}>Overview</Nav.Link>
                                    <Nav.Link href={"/saison/"+params.saison+"/plan"}>Spielplan</Nav.Link>
                                    <Nav.Link href={"/saison/"+params.saison+"/statistiken"}>Statistiken</Nav.Link>
                                    <NavDropdown title="Teams" id="basic-nav-dropdown">
                                        {
                                            !loading &&
                                                teamsData.map(team =>
                                                        <NavDropdown.Item key={team.id} href={"/saison/"+params.saison+"/team/"+team.id}>{team.name}</NavDropdown.Item>
                                                    )
                                        }
                                    </NavDropdown>
                                    <NavDropdown title="Wetter" id="">
                                        {
                                            WETTER_LIST.map(wetter =>
                                                <NavDropdown.Item key={wetter.id} href={"/saison/"+params.saison+"/wetter/"+wetter.id}>{wetter.name}</NavDropdown.Item>
                                            )
                                        }
                                    </NavDropdown>
                                    {
                                        user.token === null || user.token === "" ?
                                            <Nav.Link href={"/saison/"+params.saison+"/login"}>Login</Nav.Link>
                                        :
                                            <NavDropdown title="Mitgliedsbereich" id="basic-nav-dropdown">
                                                {
                                                    user.rolle === "Admin" && <>
                                                        <NavDropdown.Item key="berichtEintagen" href={"/saison/"+params.saison+"/bericht"}>Spielbericht eintragen</NavDropdown.Item>
                                                        <NavDropdown.Item key="berichte" href={"/saison/" + params.saison + "/member/liste"}>Änderungen freigeben</NavDropdown.Item>
                                                        <NavDropdown.Item key="admin" href={"/saison/" + params.saison + "/member/admin"}>Admin Einstellungen</NavDropdown.Item>
                                                        <NavDropdown.Item key="event" href={"/saison/" + params.saison + "/member/eventlog"}>Event Log</NavDropdown.Item>
                                                    </>
                                                }
                                                <NavDropdown.Item key="dashboard" href={"/saison/" + params.saison + "/member/dashboard"}>Dashboard</NavDropdown.Item>
                                                <NavDropdown.Item key="wetten" href={"/saison/" + params.saison + "/member/wetten"}>Wetten</NavDropdown.Item>
                                                <NavDropdown.Item key="team" href={"/saison/" + params.saison + "/member/team"}>Team Bearbeiten</NavDropdown.Item>
                                                <NavDropdown.Item key="treasury" href={"/saison/" + params.saison + "/member/treasury"}>Kassenbuch</NavDropdown.Item>
                                                <NavDropdown.Item key="user" href={"/saison/" + params.saison + "/member/user"}>Einstellungen</NavDropdown.Item>
                                                <NavDropdown.Item key="logout" onClick={event => setUser({name:"", token: "", rolle: ""})}>Logout</NavDropdown.Item>
                                            </NavDropdown>
                                    }
                                </Nav>
                            </Navbar.Collapse>
                            {
                                user.name != null && user.name !== "" &&
                                    <Navbar.Collapse className="justify-content-end">
                                        <Navbar.Text>
                                            Eingelogt als: {user.name}
                                        </Navbar.Text>
                                    </Navbar.Collapse>
                            }
                        </Container>
                    </Navbar>
                </Col>
            </Row>
            <Row>
                <Col>
                    <main>
                        <Outlet />
                    </main>
                </Col>
            </Row>
        </Container>
    );
}
